.App {
  font-family:"Old Standard TT",serif;
  line-height: 1.125;
  font-style: normal;
}

.img-hover-zoom {
  height: 320px;
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.1);
}

.main-title {
  font-size: 50px;
  letter-spacing: -1px;
  font-weight: 500;
  color: #a1761a;
}

.sub-heading {
  font-size: 45px;
  letter-spacing: -1px;
  font-weight: 400;
}

.second-sub-heading {
  font-size: 26px;
  letter-spacing: -1px;
  font-weight: 400;
}

.center {
  margin: auto;
  width: 60%;
}

.offer-text {
  font-weight: 700;
  background: burlywood;
  font-size: 22px;
  color: darkgreen;
}